import React from 'react'

function One() {
  return (
    <svg
      width="151"
      height="234"
      viewBox="0 0 151 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="151" height="234" />
      <rect x="21.5" y="9.5" width="114" height="219" stroke="white" />
      <rect x="16.5" y="9.5" width="5" height="219" stroke="white" />
    </svg>
  )
}

export default One