import React from 'react'

function Two() {
  return (
    <svg
      width="151"
      height="234"
      viewBox="0 0 151 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="151" height="234" />
      <path d="M142 7H10V21.5H16V36H10V50H16V65H10.5V79.5H15.5V94H10V109H16V123.5H10V138H15.5V152.5H10V167.5H15.5V182H10V196.5H15.5V211.5H10.5V226H142V194.5H136.5V163H142V131.5H136.5V100H141.5V69H136.5V38H142V7Z" stroke="white" />
    </svg>
  )
}

export default Two