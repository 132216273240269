import React from 'react'

function Four() {
  return (
    <svg
      width="151"
      height="234"
      viewBox="0 0 151 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="151" height="234" />
      <path d="M10.5 29.5C14.0435 31.0588 19.4741 35.8237 10.9696 40.5" stroke="white" />
      <path d="M11.5 40.5C15.2731 42.1296 20.5555 46.6111 11.5 51.5" stroke="white" />
      <path d="M10 7H132.5C127 10.5 116.5 21.5 132.5 34.5C129.5 35 117 46 131 62.5" stroke="white" />
      <path d="M131 62.5C126 66.5 119 77.5 131 89.5" stroke="white" />
      <path d="M131 89.5C126.167 93.8333 119.4 105.4 131 117" stroke="white" />
      <path d="M131 117C126.167 121.333 119.4 132.9 131 144.5" stroke="white" />
      <path d="M131.126 144.5C126.126 148.5 119.126 159.5 131.126 171.5" stroke="white" />
      <path d="M131.126 171.5C126.292 175.833 119.526 187.4 131.126 199" stroke="white" />
      <path d="M131.126 199C126.292 203.333 119.526 214.9 131.126 226.5" stroke="white" />
      <path d="M10.5 7.5C14.0435 9.12963 19.4741 14.1111 10.9696 19" stroke="white" />
      <path d="M10.5 18.5C14.2731 20.1296 19.5555 24.6111 10.5 29.5" stroke="white" />
      <path d="M11.5 72.5C15.0435 74.2005 20.4741 79.3986 11.9696 84.5" stroke="white" />
      <path d="M11.5 84.5C15.2731 86.1296 20.5555 90.6111 11.5 95.5" stroke="white" />
      <path d="M11.5 51.5C15.0435 53.0588 20.4741 57.8237 11.9696 62.5" stroke="white" />
      <path d="M11.5 62.5C15.2731 63.9815 20.5555 68.0556 11.5 72.5" stroke="white" />
      <path d="M12.5 117.5C16.0435 119.059 21.4741 123.824 12.9696 128.5" stroke="white" />
      <path d="M12.5 128.5C16.2731 130.13 21.5555 134.611 12.5 139.5" stroke="white" />
      <path d="M11.5 95.5C15.0435 97.0588 20.4741 101.824 11.9696 106.5" stroke="white" />
      <path d="M12.5 106.5C16.2731 108.13 21.5555 112.611 12.5 117.5" stroke="white" />
      <path d="M12.5 161.5C16.0435 163.059 21.4741 167.824 12.9696 172.5" stroke="white" />
      <path d="M13.5 172.5C17.2731 174.13 22.5555 178.611 13.5 183.5" stroke="white" />
      <path d="M12.5 139.5C16.0435 141.059 21.4741 145.824 12.9696 150.5" stroke="white" />
      <path d="M12.5 150.5C16.2731 152.13 21.5555 156.611 12.5 161.5" stroke="white" />
      <path d="M13.5 183.5C17.2731 185.13 22.5555 189.611 13.5 194.5" stroke="white" />
      <path d="M13.5 216.5C17.0435 217.917 22.4741 222.249 13.9696 226.5" stroke="white" />
      <path d="M13.5 194.5C17.0435 196.059 22.4741 200.824 13.9696 205.5" stroke="white" />
      <path d="M13.5 205.5C17.2731 207.13 22.5555 211.611 13.5 216.5" stroke="white" />
      <line x1="130.5" y1="227" x2="13.5" y2="227" stroke="white" />
    </svg>
  )
}

export default Four