import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import One from "../components/product/spatulas/one";
import Two from "../components/product/spatulas/two";
import Three from "../components/product/spatulas/three";
import Four from "../components/product/spatulas/four";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import {
  textFieldSx,
  selectListSx,
  formSx,
  inputLabelSx,
  menuItemSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  toggle,
  spatulasHint,
  item,
} from "../style/pages/spatulas.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import dataJson from "../data/spatulas.json";

import { StoreContext } from "../context/store-context";

function Spatulas() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "spatulas" }
          name: { nin: ["main"] }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [spatulas, setSpatulas] = useState("");
  const [engravingText, setEngravingText] = useState("");
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Выбор шпателей
  const handleSpatulas = (event) => {
    setSpatulas(event.target.value);
  };

  // Обновить состояние для текста гравировки
  const handleEngraving = (event) => {
    setEngravingText(event.target.value);
  };

  // Обновить состояние для количества подложек
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить первый набор
  const addProduts = () => {
    const dataOrder = {
      spatulas: spatulas,
      engravingText: engravingText,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      substrates: { ...store.substrates },
      screens: { ...store.screens },
      toppers: { ...store.toppers },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
      tag: { ...store.tag },
      frame: { ...store.frame },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      spatulas: {
        bundle: [...store.spatulas.bundle, dataOrder],
        totalPrice: store.spatulas.totalPrice + dataOrder.totalAmount,
      },
    });

    setSpatulas("");
    setEngravingText("");
    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.spatulas.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.spatulas.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      spatulas: {
        bundle: [...arrBundle],
        totalPrice: store.spatulas.totalPrice - deductibleAmount,
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
      tag: { ...store.tag },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      substrates: { ...store.substrates },
    });
  };

  useEffect(() => {
    dataJson
      .filter((item) => item.info === spatulas)
      .map((item) => setCost(Number(item.price)));
    let q = quantity || 0;
    let c = cost || 0;
    let e = engravingText || false;

    if (e && spatulas !== dataJson[0].info) {
      setTotalAmount(Math.ceil(q * c + q * 100));
    } else if (e && spatulas === dataJson[0].info) {
      setTotalAmount(Math.ceil(q * c + q * 400));
    } else {
      setTotalAmount(Math.ceil(q * c));
    }
  }, [quantity, cost, spatulas, engravingText]);

  return (
    <main className="mainpage">
      <MetaTag title="Шпатели из акрила | Wowmilota" />
      <motion.div
        layoutId="spatulasContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.995 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотографии шпателей"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <div className={detailHeader}>
                  <h2>Шпатели из акрила</h2>
                </div>
                <p>
                  Шпатели кондитерские из акрила, изготовлены из экологичного
                  материала, допустимого в пищевом производстве.
                  <br />
                  Самые главные плюсы наших шпателей:
                  <br />
                  Шпатель приятно держать в руке, он практически невесомый и не
                  такой острый как металлический;
                  <br />
                  Две рабочих стороны (поэтому края шпателя мы не закругляем)
                  <br />
                  Фигурный шпатель позволяет с легкостью добиться красивого
                  рельефа боковины торта.
                  <br />
                  Срез на прямом шпателе позволяет выравнивать торт и наносить
                  крем;
                </p>
                <div className={spatulasHint}>
                  <div className={item}>
                    <One />
                    <p>Шпатель №1</p>
                  </div>
                  <div className={item}>
                    <Two />
                    <p>Шпатель №2</p>
                  </div>
                  <div className={item}>
                    <Three />
                    <p>Шпатель №3</p>
                  </div>
                  <div className={item}>
                    <Four />
                    <p>Шпатель №4</p>
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Обязательные параметры</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: spatulas
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Шпатели</h3>
                  <FormControl variant="standard" fullWidth sx={formSx}>
                    <InputLabel id="demo-simple-select-label" sx={inputLabelSx}>
                      Выберите шпатель
                    </InputLabel>
                    <Select
                      sx={selectListSx}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={spatulas}
                      label="Выберите шпатель"
                      onChange={handleSpatulas}
                    >
                      {dataJson.map((item, index) => {
                        const { info } = item;

                        return (
                          <MenuItem
                            sx={menuItemSx}
                            value={info}
                            key={`topper${index}`}
                          >
                            {info}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="spatulasQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Дополнительные параметры</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: engravingText
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Гравировка</h3>
                  <div className={toggle}>
                    <TextField
                      fullWidth
                      id="spatulasEngravingText"
                      label="Текст гравировки"
                      variant="standard"
                      value={engravingText}
                      onChange={handleEngraving}
                      sx={textFieldSx}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.spatulas.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.spatulas.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.spatulas}</p>
                        {item.engravingText ? (
                          <p>С гравировкой</p>
                        ) : (
                          <p>Без гравировкой</p>
                        )}
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>

            <div className={priceInfo}>
              <div className={mobileProducts}>
                <button onClick={() => setMobileList(!mobileList)}>
                  <MdShoppingBasket color="white" />
                  <p className={nubmer}>{store?.spatulas.bundle.length}</p>
                </button>
                {mobileList && (
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    grabCursor={true}
                    className={productContainer}
                  >
                    {store?.spatulas.bundle.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={`order_${index}`}
                          className={productItem}
                        >
                          <button
                            className="delProduct"
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>{item.spatulas}</p>
                          {item.engravingText ? (
                            <p>С гравировкой</p>
                          ) : (
                            <p>Без гравировкой</p>
                          )}
                          <p>{item.quantity} шт.</p>
                          <p>{item.totalAmount} руб.</p>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
              {totalAmount > 0 ? (
                <button className={costBundle} onClick={addProduts}>
                  {totalAmount > 0
                    ? `Добавить набор ${totalAmount} руб.`
                    : "Сформируйте набор"}
                </button>
              ) : (
                <p className={costBundle}>Сформируйте набор</p>
              )}
              <Link to="/" className={mobCloseBtn}>
                <MdOutlineClose color="white" />
              </Link>
              <Link to="/" className={closeBtn}>
                {store?.spatulas.bundle.length > 0
                  ? `Сохранить и закрыть`
                  : `закрыть`}
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Spatulas;
