import React from 'react'

function Three() {
  return (
    <svg
      width="151"
      height="234"
      viewBox="0 0 151 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="151" height="234" />
      <path d="M17 7V7.5M17 7.5L10 21L16.5 34.5L10.5 48.5L16.5 61.5L10 76L16.5 89L10 103.5L16.5 116.5L10 131L16.5 144.5L10 158.5L16.5 172L10 186.5L16.5 199.5L10 213.5L16.5 227H137.5L141.5 220L137.5 213L141.5 206.5L137.5 199L141.5 192.5L137.5 186L141.5 179L137.5 172L141.5 165L137.5 158.5L141.5 151.5L137.5 144.5L141.5 138L137.5 131L141.5 123.5L137.5 117L141.5 110L137.5 103.5L141.5 96.5L137.5 89.5L141.5 83L137.5 76.5L141.5 69L137.5 62L141.5 55L137.5 49L141.5 41.5L137.5 34.5L141.5 27.5L137.5 21.5L141.5 14L137.5 7.5H17Z" stroke="white" />
    </svg>

  )
}

export default Three